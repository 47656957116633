import React from 'react'
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import Card from '../components/link_card'
import SEO from "../components/seo";

const ServiceLink = ({name, url}) => (
  <Link to={url}
        className="block py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
        activeClassName={"border-b-2 border-indigo-600 font-black"}
  >{name}</Link>
)

const CouncilAssets = ({data}) => {
  const links = data.allMdx.edges;
  
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Council Assets" />
      <div className="flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 order-last md:order-none">
          <div className="flex-shrink-0 py-4 flex flex-row items-center justify-between md:pr-6 md:border-r-1 md:border-gray-200 md:fixed md:w-64 md:order-last">
            <nav className="flex-grow md:block pb-4 md:pb-0 md:overflow-y-auto flex-wrap hidden">
                {links.map(({ node }) => (
                    <ServiceLink name={node.frontmatter.title} url={node.frontmatter.path} key={node.id} />
                ))}
            </nav>
          </div>
        </div>
        <div className="flex flex-col w-full md:pl-4">
          <div className="flex-shrink-0 py-0 flex flex-row mt-6">
            <div className="markdown-body">
              <h1>Council Assets</h1>
              <p>
                <span className="font-black">Burton upon Stather</span> Parish Council maintains many assets throughout
                the parish including the paddock, playing fields, allotments, burial ground, village hall and Thealby
                play area. It provides seats, dog bins, litter bins and bus shelters throughout the villages of{' '}
                <span className="font-black">Burton upon Stather</span>, Normanby and Thealby. The Parish Council also
                leases land on the Burton Hills from Normanby Estates and is responsible for its maintenance.’ Its
                powers and duties enable it to work directly within the community for its benefit.
              </p>
            </div>
          </div>
          <hr className="mb-4 mt-6 border-gray-500" style={{ color: '#eee' }} />
          <div className="flex flex-wrap -mx-1 lg:-mx-4">
            {links.map(({ node }) => (
              <Card
                name={node.frontmatter.title}
                url={node.frontmatter.path}
                image={node.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData}
                key={node.id}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`query CouncilAssetsQuery {
  allMdx(
    filter: {internal: {contentFilePath: {regex: "/content/council_assets/"}}}
    sort: {fields: [frontmatter___weight], order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          title
          path
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
        id
      }
    }
  }
}
`;

export default CouncilAssets
